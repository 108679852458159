var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('base-table-with-header',{attrs:{"data":{
    headerOptions : { withAddNew : true , withSearch : true , withExport : true},
    tableHeader : _vm.instructorHeader ,
    tableItems : _vm.instructorRows ,
    extraOptions : {
      footerOptions : {
        itemsPerPageText : this.$t('table.rowNumber')
      },
    },
    withActions : true
  }},on:{"search":_vm.search},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var data = ref.data;
return [_c('router-link',{attrs:{"to":{name : 'editInstructor' , params : {id : data.instructor_id} , query : { type : 'edit' }}}},[_c('v-btn',{attrs:{"text":"","icon":"","color":"secondary lighten-2"}},[_c('v-icon',[_vm._v(_vm._s(_vm.editIcon))])],1)],1),_c('router-link',{attrs:{"to":{name : 'viewInstructor' , params : {id : data.instructor_id} , query : { type : 'view' }}}},[_c('v-btn',{attrs:{"text":"","icon":"","color":"secondary lighten-2"}},[_c('v-icon',[_vm._v(_vm._s(_vm.eyeIcon))])],1)],1)]}}])},[_c('h1',{staticClass:"font-weight-bold",style:({'color' : this.primaryColor}),attrs:{"slot":"tableTitle"},slot:"tableTitle"},[_vm._v(_vm._s(_vm.$t('instructors.instructors')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }