<template>
  <v-row>
    <base-table-with-header @search="search" :data="{
      headerOptions : { withAddNew : true , withSearch : true , withExport : true},
      tableHeader : instructorHeader ,
      tableItems : instructorRows ,
      extraOptions : {
        footerOptions : {
          itemsPerPageText : this.$t('table.rowNumber')
        },
      },
      withActions : true
    }"
    >
      <h1 slot="tableTitle" class="font-weight-bold" :style="{'color' : this.primaryColor}">{{ $t('instructors.instructors') }}</h1>
      <template #default="{data}">
        <router-link :to="{name : 'editInstructor' , params : {id : data.instructor_id} , query : { type : 'edit' }}">
          <v-btn text icon color="secondary lighten-2">
            <v-icon>{{editIcon}}</v-icon>
          </v-btn>
        </router-link>
        <router-link :to="{name : 'viewInstructor' , params : {id : data.instructor_id} , query : { type : 'view' }}">
          <v-btn text icon color="secondary lighten-2">
            <v-icon>{{eyeIcon}}</v-icon>
          </v-btn>
        </router-link>
      </template>
    </base-table-with-header>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseTableWithHeader from "./Components/InstructorsTable";
import moment from 'moment';
export default {
  name: "Instructors",
  components: {BaseTableWithHeader},
  data() {
    return {
      instructorHeader: [
        {
          text: this.$t('instructors.instructorName'),
          align: 'center',
          sortable: true,
          value: 'instructor_name',
        },
        // {text: 'البريد الالكتروني', value: 'email', align: 'center'},
        {text: this.$t('instructors.registeredOn'), value: 'registerd_on', align: 'center'},
        {text: this.$t('form.birthDate'), value: 'instructor_birthDate', align: 'center'},
        {text: this.$t('instructors.totalCourses'), value: 'total_courses', align: 'center'},
        {text: this.$t('instructors.totalEnrollments'), value: 'total_enrollments', align: 'center'},
        {text: this.$t('instructors.pendingCourses'), value: 'pending_courses', align: 'center'},
        // {text: 'كامل المدفوعا', value: 'totalPaid', align: 'center'},
        {text: '', value: 'actions', align: 'center'},
      ],
      instructorRows: [],
    }
  },
  created () {
    this.fetchInstructors().then(() => {
      this.displayInstructors()
    });
  },
  methods: {
    ...mapActions("Instructors", ["fetchInstructors" , "fetchInstructor", "addInstructor", "fetchInstructorsWithSearch"]),
    search(payload) {
      console.log(payload)
      this.fetchInstructorsWithSearch(payload).then(() => {
        this.displayInstructors()
      })
    },
    displayInstructors () {
      this.instructorRows = []
       this.getInstructors.forEach(instructor => {
        const displayInstructor = {...instructor}

        displayInstructor.instructor_birthDate = moment(displayInstructor.instructor_birthDate).format("YYYY-MM-DD");

        displayInstructor.registerd_on = moment(displayInstructor.registerd_on).format("YYYY-MM-DD");

        this.instructorRows.push(displayInstructor)
      });
    }
  },
  computed: mapGetters("Instructors", ["getInstructors", "getInstructor"]),
  filters: {
    moment: function(date) {
      return moment(date).format("YYYY-MM-DD");
    }
  }
}
</script>

<style scoped>

</style>
